
$mobileS: 320px;
$mobileL: 375px;
$tabletXs: 480px;
$tabletS: 600px;
$tabletM: 800px;
$tabletL: 1024px;

// Colors
$colorWhite: #ffffff;
$colorGreen: #57b8b6;
$colorGreen2: #154d4d;
$colorGreen4: #79c573;
$colorGreen6: #45a2a0;

$colorBlack: #000000;
$colorBlack3: #3f3f3f;

$colorGrey2: #f9f9f9;
$colorGrey3: #f2f2f2;
$colorGrey5: #f3f3f3;
$colorGrey8: #999999;
$colorGrey12: #cfd2d7;
$colorGrey13: #808080;
$colorGrey14: #c1c1c1;
$colorGrey15: #b2b8bf;
$colorGrey16: #D9DBE4;

$colorDarkGrey: #333333;
$colorMiddleGrey: #999999;
$colorLightGrey: #d3d3d3;
$colorYellow: #ffbf33;
$colorYellowDark: #ffb00d;
$colorYellowDark2: #be8000;
$colorRed: #ff0000;
$colorRed2: #f97c7c;
$colorBlue: #1436E5;
$colorBlueHover: #506CFF;
$colorBlueActive: #132BA7;

@mixin error {
  &:global(.error-default) :global {
    border: 1px solid $colorRed2 !important;
    background-color: #fffcfc;
    box-shadow: none;
    animation: errorAnimation .2s linear 1 !important;
  }
  &:global(.error-blue) :global {
    border: 1px solid #aab7f8 !important;
    background-color: transparent;
    box-shadow: 0 1px 6px rgba(20, 54, 229, 0.09);
    animation: errorAnimation .2s linear 1 !important;
  }
  &:global(.error-red) :global {
    border: 1px solid $colorRed2 !important;
    background-color: #fffcfc;
    animation: errorAnimation .2s linear 1 !important;
  }
}
