.container {
  width: 590px;
  min-height: 495px;
}

.header {
  display: grid;
  height: 72px;
  padding: 0 24px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  border-bottom: 1px solid #F0F2F9;;
  text-align: left;

  div:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.body {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .addressWrapper {
    margin-top: 36px;
    display: flex;
    align-items: center;

    .address {
      margin: 0 15px;
    }
  }
}
