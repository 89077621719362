
.wrapper {
  position: relative;
}

.messageZone {
  border: dashed grey 2px;
  border-radius: 6px;
  background-color: rgba(255,255,255,.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.message {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
