.notification {
  border-radius: 4px;
  padding: 9px 26px 9px 10px;
  min-height: 42px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.6s;

  &:global(.error) {
    background: #fff7f7;
    color: #f97c7c;
  }

  &:global(.success) {
    background: #eefded;
    color: #79c573;
  }

  &:global(.show) {
    opacity: 1;
  }

  > div {
    margin-right: 10px;
  }
  > span{
    white-space: normal;
  }
}
