.wrapper {
  max-width: 24px;
  min-width: 24px;
  cursor: pointer;

  .checkbox {
    width: 24px;
    height: 24px;
    background-image: url('../../images/icons-sprite-blue.svg');
    background-position: 0px -72px;

    &:hover {
      background-position: -96px -48px;
    }

    &:global(.disabled) {
      background-position: -72px -48px;
      cursor: not-allowed;
    }

    &:global(.multiple-icon) {
      background-position: -48px -48px;
    }

    &:global(.checked) {
      background-position: -24px -48px;
    }

    &:global(.disabled.checked) {
      background-position: -48px -48px;
    }
  }
}
