.noTransactionsText {
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 40px;
}

.list {
  display: inline-block;
  box-shadow: 0 1px 0 #F0F2F9;
}

.tabTitle {
  text-align: left;
  margin: 30px 0 -1px 72px;
}

.container {
  margin-top: 0;
  margin-bottom: 50px;
  display: inline-block;
  min-width: 100%;
  text-align: center;
}

.header {
  margin: 0;
  padding: 37px 70px 18px;
  box-shadow: 0 -1px 0 #F0F2F9;
  text-align: left;
}

.headerWithoutShadow {
  margin: 0;
  padding: 37px 70px 18px;
  box-shadow: none;
  text-align: left;
}

.amount {
}

.loaderWrapper {
  display: flex;
  justify-content: center;

  div {
    margin: 5px auto;
  }

  button {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
