.wrapper {
  width: 100px !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-image: url('../../../images/user-gray.svg');
  background-repeat: no-repeat;
  background-color: #f9f9f9;
  background-size: 50%;
  background-position: center center;
  opacity: 1 !important;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  .loading {
    content: '';
    background: rgba(1, 1, 1, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 50%;
  }

  .icon {
    width: 42px;
    height: 42px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    right: -10px;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0 3px 5px rgba(20, 54, 229, 0.1);
    align-items: center;
    justify-content: center;
  }

  .fileInput {
    display: none;
  }
}

.previewWrapper {
  margin: 30px auto;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  align-items: center;
  height: 100px;
  grid-column-gap: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .title {
    text-align: right;
  }

  .defaultAvatar {
    background: #F6FBFB;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      transform: scale(2.2);
    }
  }

  .useDefaultButton {
    text-align: left;
  }
}

.avatarText {
  display: flex;
  align-items: center;

  div {
    margin-right: 12px;
  }
}

.editorWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  min-height: 230px;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 20px;

  button {
    width: 324px;
  }
}

.messageHeader {
  display: grid;
  height: 72px;
  padding: 0 24px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  border-bottom: 1px solid #F0F2F9;;
}

.avatarErrorWrapper {
  position: absolute;
  top: 30px;
  right: 30px;
}

.errorWrapper {
  display: flex;
  width: 324px;
  margin: 0 auto;
  align-items: center;

  div {
    margin-right: 10px;
  }

}
