
.wrapper {
  height: calc(100% - 100px);
  display: grid;
  grid-template-rows: 100px 1fr 210px;
  align-items: center;
  justify-content: center;

  > div:last-child {
    width: 100vw;
  }

  .logo {
    margin: 25px auto 0;
    width: 180px;
    height: 30px;
    display: flex;
    top: 18px;
    left: 28px;
    justify-content: flex-start;
    align-items: center;
    background-image: url('../../images/main_logo.svg');
    background-position: left top;
    background-size: 180px;
    background-repeat: no-repeat;
  }

  .image {
    margin: 0 auto;
    width: 260px;
    height: 260px;
    background-image: url('../../images/mobile_error_person.svg');
    background-size: 260px;
    background-repeat: no-repeat;
  }

  .title {
    text-align: center;
  }

  .subTitle {
    margin: 10px auto 0;
    width: 270px;
  }

  .button {
    width: 270px;
    margin: 20px auto 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #1436E5;
    font-family: "Nunito Sans", Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%
  }
}
