.persons {
  div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 118px;
    top: 50vh;
    opacity: 0;
    transition: opacity 1s;

    &.show {
      opacity: 1;
    }
  }

  .checkMail {
    background: url("../../images/check_mail_person.svg");
    width: 282px;
    height: 755px;
    margin-top: calc(-755px / 2);
  }

  .signIn {
    background: url("../../images/sign_in_person.svg");
    width: 370px;
    height: 557px;
    margin-top: calc(-557px / 2);
  }

  .signUp {
    background: url("../../images/sign_up_person.svg");
    width: 346px;
    height: 464px;
    margin-top: calc(-464px / 2);
  }

  .checkCode {
    background: url("../../images/confirm_code_person.svg");
    width: 325px;
    height: 251px;
    margin-top: calc(-251px / 2);
  }
}

.title {
  height: 80px;
  text-align: left;
  width: 100%;

  white-space: nowrap;
  overflow: visible;
  font-family: "Nunito Sans", Arial;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 121%;
  margin-bottom: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
    height: auto;
    padding: 0 5px;
    white-space: normal;
  }
}

.inputLabel {
  text-align: left;
  margin-bottom: 20px;
  font-family: "Nunito Sans", Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;

  @media screen and (max-width: 1024px){
    font-size: 13px;
    text-align: center;
  }
}

.inputs {
  display: flex;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    button {
      height: auto;
    }

    input {
      height: auto;
    }
  }

  @media screen and (min-width: 1025px) {
    flex-direction: row;

    button {
      width: 200px;
      height: 68px;
    }

    input {
      height: 68px;
    }
  }

  > div {
    margin-right: 25px;
    @-moz-document url-prefix() {
      input::placeholder {
        line-height: 42px;
      }
    }

    input {
      margin: 0;
      letter-spacing: 0.8px;
      padding-left: 22px;
    }
  }

  button {
    font-family: "Nunito Sans", Arial;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
}

.loader {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  background-image: url("../../images/paymentx-rolling-loader.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
  }
}

.passwordsErrors {
  margin-top: 10px;
  margin-bottom: -5px;
  display: flex;
  align-items: center;

  span {
    margin-left: 7px;
  }
}

.additionalActions {
  margin-top: 16px;
  width: 370px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    width: auto;
    margin-top: 0;

    > * {
      margin-top: 25px !important;
    }
  }
}

.goBack {
  display: flex;

  span,
  button {
    margin-left: 6px;
    line-height: 25px;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
}

.passwords {
  display: flex;

  @-moz-document url-prefix() {
    input::placeholder {
      line-height: 42px;
    }
  }

  > *:first-child {
    margin-right: 30px;
  }

  input {
    height: 68px;
    margin: 0;
    flex: 1;
    letter-spacing: 0.8px;
    padding-left: 20px;

    @media screen and (max-width: 1024px) {
      height: auto;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    > *:nth-child(2) {
      margin-top: 25px;
    }
  }
}

.passwordButtonsWrapper {
  margin-top: 30px;

  button {
    height: 68px;
    width: 100%;

    font-family: "Nunito Sans", Arial;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      height: auto;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url("../../images/login_background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
}

.loginWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 1170px;
  position: absolute;
  top: 22px;

  .logo {
    background-image: url("../../images/main_logo.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 163px;
    height: 35px;
  }

  @media screen and (max-width: 1024px) {
    justify-content: center;
    position: static;
    height: auto;
    margin-bottom: 60px;
    margin-top: 25px;
  }
}

@keyframes openSlideAnimation {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-shrink: 0;
  flex-basis: auto;
  max-width: 100%;
  justify-content: flex-start;
  width: 550px;
  min-width: 250px;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }

  form {
    flex-grow: 1;
    margin-top: 17px;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;

    .fieldsWrapper {
      min-height: 200px;
      width: 100%;
    }

    .fieldsWrapperAnimated {
      display: flex;
      width: 100%;
      flex-direction: column;

      @media screen and (min-width: 1025px) {
        transform: translateX(500px);
        min-height: 200px;
        animation: openSlideAnimation 0.5s;
        animation-fill-mode: forwards;
      }
    }

    :global(.backButton) {
      button {
        border-radius: 50%;
        position: absolute;
        width: 40px;
        height: 40px;
        min-width: 40px !important;
        min-height: 40px !important;
        left: -20px;
        top: 90px;
      }
    }
  }

  :global(.successMessageContainer) {
    flex-grow: 1;
    padding-top: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    z-index: 1;

    button {
      margin-top: 20px;
    }
  }

  :global(.messageContainer) {
    flex-grow: 1;
  }

  :global(.anotherEmail) {
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 15px;
  }
}

.popupContainer {
  display: flex;
  width: 500px;
  height: 170px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    margin: 10px;
  }
}

.footer {
  position: absolute;
  bottom: 15px;
  left: 148px;
}

@media screen and (max-width: 1024px) {
  .footer {
    position: static;
    left: auto;
    bottom: auto;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 15px;
    font-size: 12px !important;
    margin-top: 100px;
  }
}

.buttonsWrapper {
  margin-bottom: 25px;

  button {
    margin-bottom: 20px;
    width: 100%;
  }
}
