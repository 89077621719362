.messageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  min-height: 422px;

  > div {
    margin: 5px 0;
    text-align: center;

    &:first-child {
      transform: scale(2);
      margin: 0 0 18px 0;
    }
  }
}

.messageActions {
  display: flex;
  justify-content: space-around;
  margin-top: 14px !important;

  button {
    margin: 0 11px;
  }
}

.addPaymentRequestFormContainer {
  min-height: 422px;
  padding: 30px 40px 30px;
  text-align: center;

  .message {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .firstStepWrapper {
    text-align: left;
    display: inline-block;
    min-width: 480px;
    width: 100%;
  }

  .secondStepWrapper {
    text-align: left;
    display: inline-block;
    min-width: 480px;
  }

  .amount {
    text-align: left;

    .amountLine {
      display: grid;
      grid-template-columns: 1fr 130px;
      align-items: flex-start;
      grid-column-gap: 10px;
      margin-bottom: 20px;

      .selectWrapper {
        > label {
          display: block;
          margin-bottom: 11px;
        }
      }
    }
  }

  .rates {
    margin-top: 20px;
    display: flex;
    align-items: center;

    > span:first-child {
      margin-right: 7px;
    }
  }

  .action {
    margin-top: 20px;
    margin-bottom: 10px;

    button {
      width: 100%;
    }
  }
}

.sendEmailFormLoadingWrapper {
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;

  div {
    margin: 2px !important;
  }
}

.messageContainer {
  width: 590px;
  min-height: 495px;
}

.messageHeader {
  display: grid;
  height: 72px;
  padding: 0 24px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  border-bottom: 1px solid #F0F2F9;;
  text-align: left;
}

.resultTable {
  display: grid;
  grid-template-columns: 3fr 6fr;
  align-items: center;
  row-gap: 25px;
  column-gap: 10px;

  .accountRow {
    display: flex;
    align-items: center;

    > div:first-child {
      margin-right: 10px;
    }
  }
}

.qrCodeWrapper {
  margin: 28px auto 20px;
  display: flex;
  justify-content: center;
}

.copyButtonWrapper {
  margin-top: 10px;
  text-align: center;

  > div {
    display: inline-block;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  > div:first-child {
    margin-right: 10px;
  }
}

.warning {
  text-align: center;
}

.sendToEmailWrapper {
  text-align: center;

  .actionButton {
    display: inline-flex;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  > div {
    display: grid;
    grid-template-columns: 250px 115px;
    grid-column-gap: 20px;
    margin: 14px auto 0;
    justify-content: center;
  }

}
