@import "../../styles/index.module";

// region styled
.styledButton {
  outline: none;
  border: none;
  background: inherit;
  color: $colorWhite;
  box-shadow: none;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  min-width: 84px;
  min-height: 42px;
  padding: 10px 30px;
  white-space: nowrap;

  font-family: 'Nunito Sans', Arial;
  font-style: normal;
  font-weight: 900;
  font-size: 11px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 20px;
  }

  &:active {
    color: white;
    background-color: $colorYellowDark;
  }

  &.login {
    background-color: $colorWhite;
    color: $colorBlue;
    border: 2px solid $colorBlue;
    border-radius: 3px;

    &:hover {
      background-color: $colorWhite;
      color: $colorBlue;
      border: 2px solid $colorBlue;
    }
    &:active, &:focus {
      background-color: $colorWhite!important;
      color: $colorBlue!important;
      border: 2px solid $colorBlue;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $colorWhite;
      color: $colorGrey16 !important;
      border: 2px solid $colorGrey16 !important;

      &:hover {
        background-color: 2px solid $colorWhite;
        color: $colorGrey16;
        border: 2px solid $colorGrey16;
      }
    }
  }

  &.new-filled {
    background-color: $colorBlue;
    color: $colorWhite;
    border: 2px solid $colorBlue;
    border-radius: 3px;

    &:hover {
      background-color: $colorBlueHover;
      color: $colorWhite;
      border: 2px solid $colorBlueHover;
    }
    &:active, &:focus {
      background-color: $colorBlueActive !important;
      color: $colorWhite !important;
      border: 2px solid $colorBlueActive;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $colorGrey16;
      color: $colorWhite !important;
      border: 2px solid $colorGrey16 !important;

      &:hover {
        background-color: 2px solid $colorWhite;
        color: $colorGrey16;
        border: 2px solid $colorGrey16;
      }
    }
  }

  &.new-border {
    background-color: $colorWhite;
    color: $colorBlue;
    border-radius: 3px;
    border: 2px solid $colorBlue;

    &:hover {
      background-color: $colorWhite;
      color: $colorBlueHover;
      border: 2px solid $colorBlue;
    }
    &:active, &:focus {;
      background-color: $colorWhite;
      color: $colorBlue;
      border: 2px solid $colorBlue;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: $colorGrey16;
      color: $colorWhite !important;
      border: 2px solid $colorGrey16 !important;

      &:hover {
        background-color: 2px solid $colorWhite;
        color: $colorGrey16;
        border: 2px solid $colorGrey16;
      }
    }
   };

  &.dropDownButton {
    background: #1436E5 !important;
    border: 2px solid #1436E5;

    &:hover {
      background: #506CFF !important;
      border: 2px solid #506CFF;
    }

    &.activeButton {
      background: #132BA7 !important;
      border: 2px solid #132BA7;
    }
  }
}
