.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 422px;

  .form {
    display: block;
    width: 324px;

    > div {
      margin-bottom: 20px;
    }

    .title {

    }

    .input {
      label {
        margin-bottom: 6px;
      }
    }

    .action {
      button {
        width: 100%;
      }
    }
  }

  .phraseContainer {
    margin-top: -10px;
    width: 440px;
  }

  .wordsGrid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .word {
    background: #f6fbfb;
    border-radius: 5px;
    padding: 11px 0;
    text-align: center;

    span {
      margin-right: 10px;
      color: #C1C1C1;
    }

    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: #333333;
  }
}

.copyButtonWrapper {
  display: flex;
  margin-top: 30px;

  :global(.button) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  :global(.copy-message) {
    right: -12px;
  }
}
