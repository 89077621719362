form[name=confirm-payroll-form] {
  height: 100%;
  width: 100%;
}

.blockchainPartContainer {
  width: 100%;
  display: flex;
  min-height: 160px;
  flex-direction: column;
  margin-top: 23px;
}

.headerConfirm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px 7px 15px;
  margin-left: -5px;

  :global(.add-btn) {
    padding: 10px 55px;
    width: 100%;
  }
  :global(.confirm-title) {
    flex: 1;
    text-align: center;
    padding: 0;
  }
}

.scrollContainer {
  padding-bottom: 10px;
}

.confirmDetailsContainer {
  padding: 0 15px 10px 15px;
  display: flex;
  flex-direction: column;
}

.containerConfirm {
  padding: 0 7px 40px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 100%;
  font-family: 'Nunito Sans', Arial;

  :global(.wallet-line) {
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 20px;
    flex-direction: row;
    min-height: 46px;
    border-radius: 10px;
    background-color: #FFDB21;
    margin: 12px 0 0 0;

    /*
      const NETWORK_COLORS = {
        [BLOCKCHAIN_BITCOIN]: '#FFDB21',
        [BLOCKCHAIN_BITCOIN_TESTNET]: '#50EFF5',
      }
     */
  }

  :global(.confirm-password-input) {
    margin: 20px 0 0 0;
    width: auto;
    label {
      margin-bottom: 5px;
    }
  }

  div:nth-last-child(2) {
    border-color: transparent;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
  }
}

.preloader {
  position: fixed;
  flex-direction: column;
  text-align: center;
  top: 0;
  bottom: 0;
  right: 0;
  width: 265px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  > div:first-child {
    div {
      margin: 16px;
    }
  }

  > div:last-child {
    margin-top: 6px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    position: static;
    height: 100%;
  }
}

.errorLine {
  display: grid;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f2f9;
  color: #f97c7c;
  grid-template-columns: 31px 1fr;
  border-bottom: 1px solid transparent;
  margin-top: 10px;
  >div{
    margin: 0;
  }
}

.confirmWalletLine {
  display: grid;
  grid-template-columns: 38px 1fr;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f2f9;
}

.steps {
  display: flex;
  justify-content: center;
  padding: 0 0 3px 0;

  div{
    background: #f0f2f9;
    border-radius: 50%;
    margin: 5px;
    width: 10px;
    height: 10px;
    &.active{
      background: #ffffff;
      border: 1px solid #1436e5;
      box-sizing: border-box;
    }
  }
}

.exchangeIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  background-image: url("../../images/activity.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.billIcon {
  position: absolute;
  top: 12px;
  z-index: 100;
  min-width: 24px;
  min-height: 24px;
  margin-right: 15px;
  background-image: url("../../images/bill.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.feeSelect {
  width: 100%;
  margin: 0;
  background-color: white;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  font-size: 13px;
  line-height: 130%;
  color: #5b5d69;
  text-align: left;
  outline: none;
  height: 100%;
  appearance: none;
}

.walletRelativeContainer {
  margin-right: 0 !important;
  display: flex;
  width: 100%;
  height: 51px;
  position: relative;

  :global(.chevron) {
    right: -6px !important;
  }

  >div:first-child {
    margin-right: 15px;
  }

  select {
    height: 20px;
  }
}

.walletBalance {
  width: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 20px;

  align-items: center;
  margin-left: 20px;
  flex-direction: row;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  color: #5b5d69;
}

.walletNotEnoughMoney {
  display: flex;
  width: 100%;
  color: #f97c7c;
  margin-left: 10px;
  font-family: "Nunito Sans", Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
}

.totalDebited {
  align-items: center;
  display: grid;
  grid-template-columns: 39px 1fr;
  padding: 0 15px;

  >div:first-child {
    transform: translateY(-7px);
  }
}

.lineText {
  display: flex;
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #5b5d69;
}

.walletName {
  overflow: hidden;
  padding-right: 20px;

  >div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.walletLine {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f2f9;
  flex: 1;
  min-height: 38px;
  padding: 15px 8px 15px 0;

  &.accounts {
    padding: 3px 0;
  }


  :global(.chevron) {
    position: absolute;
    right: 30px;
  }

  &:last-child {
    border-bottom: none;
  }

  >div:first-child {
    margin-right: 15px;
  }

  .checkbox {
    transform: scale(0.67);
  }

  .feeRelativeContainer {
    margin-right: 0 !important;
    display: flex;
    position: relative;
    width: 100%;
    height: 51px;
    align-items: center;

    .accountWrapper {
      width: 100%;
    }
  }
}

.mainTotal {
  display: flex;
  justify-content: center;
  margin: 0 0 10px;

  >div:first-child {
    margin-right: 10px;
  }
}

.header {
  padding: 0 0 8px 0;

  :global(.add-btn) {
    padding: 10px 55px;
    width: 100%;
  }
}

.header+div {
  margin: 0;
}

.separatedLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -29px;
  width: 160px;
}

.resetPasswordButton {
  padding-top: 10px;
  text-align: right;

  button {
    width: auto;
  }
}

.message {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 15px;
  text-align: center;

  >div:first-child,
  >i:first-child {
    transform: scale(2, 2);
    transform-origin: center bottom;
    margin-bottom: 10px;
    color: #79C573;

    &.fail {
      color: #B9BABF;
    }
  }

  button {
    cursor: pointer;
    margin-top: 30px;
  }
}

.optionWrapper {
  .singleOption {
    display: none;
    align-items: center;
    white-space: normal;

    .fakeIcon {
      min-width: 24px;
      width: 24px;
      max-width: 24px;
    }

    .ellipsis {
      overflow: hidden;

      >div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    >div:first-child {
      margin-right: 15px;
    }
  }

  .listOption {
    display: block;
  }
}

.actionsWrapper {
  margin-top: 15px;
  text-align: center;

  button {
    width: 100%;
    margin-bottom: 0px;
  }
}
