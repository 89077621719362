.copyWrapper {
  position: relative;
  overflow: visible;

  .copiedMessage {
    display: "block";
    position: absolute;
    top: -45px;
    right: -65px;
    text-align: center;
    line-height: 22px;
    background: #151828;
    border-radius: 4px;
    padding: 8px 15px;
    white-space: nowrap;
    &::after {
      content: " ";
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: #151828;
      position: absolute;
      bottom: -5px;
      left: calc(50% - 5px);
    }
  }
}
