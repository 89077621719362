.sidePanelInfoLine {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f2f9;
  flex: 1;
  min-height: 38px;
  padding: 15px 8px 15px 0;

  :global(.chevron) {
    position: absolute;
    right: 30px;
  }

  &:last-child {
    border-bottom: none;
  }

  > div:first-child {
    margin-right: 15px;
  }

  select {
    z-index: 1;
    background: transparent;
    overflow: hidden;
    white-space: normal;
    padding-right: 15px;
    text-overflow: ellipsis;
  }

  :global(.description) {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
  }

  :global(.value) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    color: #5B5D69;

    &:global(.exchange-rate) {
      align-items: flex-start;
      flex-direction: column;
    }

    :global(.status-icon) {
      margin-right: 5px;
    }

    :global(.exhange-rate-value) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #5B5D69;
      margin-bottom: 7px;
    }

    :global(.exhange-rate-date) {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      white-space: nowrap;
      line-height: 140%;
      color: #8A8B93;
    }

    :global(.exhange-rate-source-name) {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 130%;
      color: #8A8B93;
    }
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logoWrapper {
  margin-top: 30px;
  display: flex;

  .logoImage {
    max-width: 250px;
    max-height: 250px;
    margin-right: 15px;
    animation: show .5s;
  }

  .logoLoader {
    margin: 40px;
    width: 45px;
    height: 45px;
    display: flex;
    background: url("../../images/paymentx-rolling-loader-no-ratate.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: rotating .8s linear infinite;
  }
}

.billToWrapper {
  display: grid;
  grid-template-columns: 24px 1fr;

  &:global(.avatar) {
    grid-template-columns: 36px 1fr;
  }

  grid-column-gap: 17px;
  padding: 9px 17px;
  align-items: center;
  background: #f6fbfb;
  border-radius: 4px;
  height: 64px;

  .iconWrapper {
    display: flex;
    justify-content: center;
  }

}

.billFromWrapper {
  > button {
    display: flex;
    align-items: center;
  }

  .billFromContent {
    margin-top: 10px;
    white-space: pre-line;
    margin-bottom: 25px;
  }
}

.PDFActionsWrapper {
  border-top: 1px solid #f0f2f9;
  padding-top: 10px;
  margin-bottom: 100px;

  .loadingWrapper {
    padding: 0;

    div {
      margin: 0;
    }
  }

  .action {
    display: flex;
    align-items: center;
    padding: 11px 0 10px;

    span {
      margin-left: 10px;
    }
  }

  .emailWrapper {
    margin-top: 10px;

    button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .successMessage {
    text-align: center;
    margin-top: 10px;
  }
  .errorWrapper{
    text-align: center;
  }
}

.wrapper {
  display: block;
  height: 100%;
  width: 100%
}

.panel {
  display: grid;
  grid-template-columns: 1fr 265px;
  grid-template-areas: "main sd";
}

.buttonContainer {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  >button {
    width: 100%;
  }
}

.mainContent {
  grid-area: main;
  .qr-code {
    display: none;

    &.open {
      display: block;
    }
  }
}

.form {

}

.sectionTitle {
  margin-bottom: 29px;
  margin-top: 31px;
}

.section {
  width: 590px;
  margin: 0 auto;
  padding-bottom: 9px;
  &:last-child {
    margin-bottom: 50px;
  }
}

.line {
  display: grid;
  grid-template-columns: 200px 1fr;
  margin: 20px 0;
  &.vertAlignTop{
    align-items: flex-start;
    :first-child {
      padding-top: 10px;
    }
  }
  label {
    display: flex;
    align-items: center;
    color: #8A8B93;
  }
}

.lineData {
  display: flex;
  flex: 1;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #5B5D69;

  &.preFormat {
    white-space: pre-line;
  }

  >div:first-child {
    margin-right: 10px;
  }
  .copy-wrapper {
    margin-left: 10px;
  }
  .view-on-blockchain-link {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #1436E5;
    cursor: pointer;
  }
}

.separator  {
  margin: 0 30px 0 27px;
  border-bottom: 1px solid #f0f2f9;
}

.itemTotalWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 20px;
  button {
    display: flex;
    align-items: center;
    div {
      margin-right: 10px;
    }
  }
  span {
    margin-left: 14px;
  }
}

.notes {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #5B5D69;
}

.notificationsWrapper {
  position: absolute;
  right: 30px;
  width: 200px;
  overflow: hidden;
  top: 30px;
}


.paymentContainer {
  margin-top: 0;

  &.isConfirmed {
    margin-top: -20px
  }

  .BlockchainPart {
    margin-top: 0;
  }
}

.summary {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 30px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #8A8B93;
  margin-top: -6px;

  :global(.summary-label) {
    color: #8A8B93;
    grid-column: 1 / 3;
    margin-bottom: -20px;
  }
  :global(.summary-amount) {
    grid-column: 1 / 3;

    .received {
      color: #79c573;
    }
    .sent {
      color: #f97c7c;
    }
    .crypto-amount {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: #8A8B93;
    }

    div {
      display: block;
      color: #5B5D69;
    }
    span {
      margin-left: 6px;
    }
  }
  .description {
    display: flex;
    justify-content: flex-start;
    padding-top: 5px;
  }
  .value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    color: #5B5D69;

    &.exchange-rate {
      align-items: flex-start;
      flex-direction: column;
    }

    .status-icon {
      margin-right: 5px;
    }
    .exhange-rate-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #5B5D69;
      margin-bottom: 7px;
    }
    .exhange-rate-date {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      white-space: nowrap;
      line-height: 140%;
      color: #8A8B93;
    }
    .exhange-rate-source-name {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 130%;
      color: #8A8B93;
    }
  }
}

.sideContent {
  grid-area: sd;
  background: #ffffff;
  box-shadow: 0 1px 100px rgba(240, 242, 249, 0.5);

  .SideContent__content {
    padding: 31px 27px 31px 30px;
  }

  :global(.request-pay-container) {
    padding: 0 !important;
    width: 100% !important;
  }
  :global(.wallet-line) {
    margin-right: 0 !important;
  }
  :global(.wallet-title) {
    margin-right: 0 !important;
  }
  :global(.nowallets-container) {
    height: 100% !important;
    justify-content: flex-start !important;
    margin-top: 50px;
  }
  :global(.confirm-requestpay-container) {
    padding-left: 0 !important;
    width: 100% !important;
    padding-right: 0 !important;
  };
}

.header {
  border-bottom: 1px solid #f0f2f9;
  padding: 22px 72px 21px;
  position: relative;

  .closeButton {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
  }
}

.loadingState {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 40px;
  width: 40px;
  background: url('../../images/paymentx-rolling-loader.svg');
  background-size: cover;
}

.attachment {
  width: 100%;
  display: flex;
  margin-top: 9px;
  margin-bottom: 14px;
}

.attachmentTitle {
  flex: 1;
  cursor: pointer;
  text-decoration: none;
  padding-left: 7px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #5B5D69;
}

.attachmentsWrapper {
  width: 100%;
}

.itemTable {
  display: grid;
  grid-template-columns: 1fr 80px 60px 90px;

  &.amountType {
    grid-template-columns: 1fr 135px !important;
  }
}

.itemTableTitle {
  @extend .itemTable;

  margin-bottom: 9px;
}

.itemTableBody {
  @extend .itemTable;

  flex-grow: 1;
  flex-shrink: 1;
  border: 1px solid #d9dbe4;
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(240, 242, 249, 0.5);
  grid-gap: 1px;
  background-color: #d9dbe4;
  border-radius: 4px;

  >div {
    background-color: #ffffff;
    padding: 17px 10px 16px 10px;
    text-align: right;

    &.description {
      text-align: left;
    }

    &:first-child {
      border-top-left-radius: 3px;
    }

    &:nth-child(2) {
      border-top-right-radius: 3px;
    }

    &:nth-child(2n+2) {
      padding: 0 15px 0 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &:last-child {
      border-bottom-right-radius: 3px;
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 3px;
    }
  }
}
