.container {
  min-width: 206px;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .buttonWrapper {
    display: flex;

    > button {
      padding: 6px 20px;

      &:first-child {
        flex-grow: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 30px;
        min-width: 30px;
        padding-right: 0;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.dropDownButton {
        background: #1436E5 !important;
        border: 2px solid #1436E5;

        &:hover {
          background: #506CFF !important;
          border: 2px solid #506CFF;
        }

        &.activeButton {
          background: #132BA7 !important;
          border: 2px solid #132BA7;
        }
      }
    }
  }

  .dropDownMenu {
    position: absolute;
    overflow: hidden;
    background: #FFFFFF;
    width: 100%;
    display: flex;
    box-shadow: 0 10px 10px transparent;
    height: 0;
    transition: all 0.2s ease;

    &.active {
      height: 40px;
      box-shadow: 0 10px 10px #F0F2F9;
      z-index: 1;
    }

    button {
      height: 40px;
      width: 100%;
      padding: 10px 15px;
      text-align: left;

      &:hover, &:active {
        background: #F6FBFB;
      }
    }
  }
}
