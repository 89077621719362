.billToSelectedWrapper {
  transform: translateY(-120%);
  display: grid;
  grid-template-columns: 24px 1fr 24px;

  &:global(.avatar) {
    grid-template-columns: 36px 1fr 24px;
  }

  grid-column-gap: 17px;
  padding: 9px 17px;
  align-items: center;
  background: #f6fbfb;
  border-radius: 4px;
  height: 64px;

  > img {
    border-radius: 50%;
    max-width: 100%;
    max-height: 100%;
  }

  div.iconWrapper {
    display: flex;
    justify-content: center;
  }

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  button:disabled {
    cursor: not-allowed;

    div {
      cursor: not-allowed;
    }
  }
}

.noteStyles {
  textarea {
    white-space: pre-line;
  }
}

.billToOption {
  display: grid;
  align-items: center;
  grid-template-areas: "icon name" "icon email";
  grid-template-columns: 53px 1fr;

  &:global(.withoutEmail) {
    grid-template-areas: "icon name" "icon name";

    > div.billToOptionEmail {
      display: none;
    }

    > div.billToOptionName {
      grid-area: name;
      min-height: 48px;
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  > .billToOptionIcon {
    grid-area: icon;
    display: grid;
    grid-row: 1 / span 2;
    justify-content: flex-start;

    .avatarWrapper {
      > img {
        border-radius: 50%;
        max-width: 100%;
        max-height: 100%;
      }

      width: 36px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > div.billToOptionName {
    grid-area: name;

    span {
      word-break: initial;
    }
  }

  > div.billToOptionEmail {
    grid-area: email;

    span {
      word-break: initial;
    }
  }

  .newEmailSingleLabel {
    display: none;
  }

  .newEmailLabel {
    display: inline;
  }
}

.billToFieldWrapper {
  height: 64px;

  .billToSelect {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}

.logoWrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
    margin-right: 8px;
  }

  .logoImage {
    max-width: 250px;
    max-height: 250px;
    padding: 15px 18px;
    border: 1px solid #f0f2f9;
    border-radius: 6px;
    box-sizing: content-box;
    margin-right: 15px;
  }
}

.fileInput {
  display: none;
}

.attachmentArea {
  border: 2px dashed #f0f2f9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 18px 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  &:global(.logo) {
    margin-bottom: -10 px;
    margin-top: 30px;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
      margin-right: 10px;
    }
  }
}

.files {
  margin-top: 30px;
  margin-right: -6px;

  > div {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    margin: 20px 0;

    > div:nth-child(2) {
      padding: 0 11px;
      line-height: 25px;
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logoLoader {
  background: url('../../images/paymentx-rolling-loader-no-ratate.svg');
  animation: rotating .8s linear infinite;
  background-size: contain;
  width: 45px;
  height: 45px;
  margin: 40px;
}

.fileLoader {
  background: url('../../images/paymentx-rolling-loader.svg');
  background-size: contain;
}

.loadingContainer {
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    text-align: center;

    > div:first-child {
      transform: scale(4, 4);
      transform-origin: center bottom;
      margin: 0 auto 8px;
    }

    > button {
      margin: 29px auto;
      padding: 10px 24px;
    }
  }

  .emptyMessage {
    margin-top: 65px;
    text-align: center;

    > div:first-child {
      transform: scale(4, 4);
      transform-origin: center bottom;
      margin: 0 auto 35px;
    }

    > div:nth-child(2) {
      margin-bottom: 15px;
    }

    > div:nth-child(3) {
      letter-spacing: -0.4px;
    }

    > button {
      margin: 20px auto;
      padding: 10px 24px;
    }
  }
}

.itemTable {
  display: grid;
  grid-template-columns: 1fr 81px 79px 90px;

  &.itemTableTitle {
    margin-bottom: 9px;
    grid-template-columns: 1fr 102px 61px 91px;
  }

  &.itemTableBody {
    flex-grow: 1;
    flex-shrink: 1;
    border: 1px solid #d9dbe4;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(240, 242, 249, 0.5);
    grid-gap: 1px;
    background-color: #d9dbe4;
    border-radius: 4px;

    textarea {
      font-family: 'Nunito Sans', Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 14px !important;
      line-height: 140% !important;
      color: #5b5d69 !important;
      min-height: 49px;
      padding: 15px 20px 13px !important;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px !important;
        line-height: 140% !important;
        color: #b9babf !important;
      }
    }

    input {
      height: 49px;
      padding: 4px 19px;
      border-radius: 0;
      font-size: 14px;
      padding: 15px 10px 13px !important;

      &::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px !important;
        line-height: 140% !important;
        color: #b9babf;
      }
    }

    > div {
      background-color: #ffffff;
      padding: 17px 0 16px 20px;
      text-align: right;

      input {
        text-align: right;
      }

      textarea {
        text-align: left;
      }

      &:first-child {
        border-top-left-radius: 3px;

        input, textarea {
          border-top-left-radius: 3px;
        }
      }

      &:nth-child(4) {
        border-top-right-radius: 3px;

        input, textarea {
          border-top-right-radius: 3px;
        }
      }

      &:nth-child(4n+4) {
        padding: 15px 10px 13px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &:last-child {
        border-bottom-right-radius: 3px;

        input, textarea {
          border-bottom-right-radius: 3px;
        }
      }

      &:nth-last-child(4) {
        border-bottom-left-radius: 3px;

        input, textarea {
          border-bottom-left-radius: 3px;
        }
      }

      &:global(.input) {
        padding: 0;
      }
    }

    input, textarea {
      border-color: transparent;
      border-radius: 0;
    }
  }

  &.itemTableBodyAmountType {
    grid-template-columns: 1fr 135px;

    > div {
      &:nth-child(2) {
        border-top-right-radius: 3px;

        input, textarea {
          border-top-right-radius: 3px;
        }
      }

      &:nth-last-child(2) {
        border-bottom-left-radius: 3px;

        input, textarea {
          border-bottom-left-radius: 3px;
        }
      }
    }
  }

  :global(.input) {
    display: flex;

    div {
      height: 100%;
    }

    input, textarea {
      height: 100%;
      border-color: transparent;
      border-radius: 0;
    }
  }

  :global(.input.description) {
    position: relative;

    div:global(.shadow) {
      padding: 15px 20px 13px;
      text-align: left;
      font-family: 'Nunito Sans', Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
    }

    div:global(.shadow) ~ div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      textarea {
        overflow: hidden;
        max-height: inherit;
      }
    }
  }
}

.itemTableWrapper {
  display: flex;
  margin-right: -30px;
}

.itemActions {
  display: flex;
  flex-direction: column;
  width: 30px;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  button {
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
  }
}

.itemTotalWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;

  button {
    display: flex;
    align-items: center;

    div {
      margin-right: 10px;
    }
  }

  span {
    margin-left: 13px;
  }
}

.sideContent {
  grid-area: sd;
  background: #ffffff;
  box-shadow: 0px 1px 100px rgba(240, 242, 249, 0.5);
  padding: 31px 27px 31px 30px;

  .sendButtonWrapper {
    position: relative;

    .hoverBLock {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  > button, .sendButtonWrapper > button {
    width: 100%;
    margin-bottom: 20px;
  }

  .summary {
    margin: 10px 0 0 0;

    :global(.summary-amount) {
      margin: 5px 0 0 0;

      div {
        display: inline-block;
      }

      span {
        margin-left: 6px;
      }
    }
  }

  .failedMessage {
    margin-top: 65px;

    > div {
      margin: 0 auto 9px;
      text-align: center;

      &:first-child {
        transform: scale(2, 2);
        transform-origin: center bottom;
      }
    }
  }
}

.mainContent {
  grid-area: main;

  form {
    padding-bottom: 20px;
  }

  .section {
    width: 590px;
    margin: 0 auto;
    padding-right: 98px;
    padding-bottom: 9px;

    &.accountsSection {
      padding-right: 0;
      width: 810px;
      padding-left: 110px;
    }

    &.itemsSection {
      padding-right: 0;
      width: 810px;
      padding-left: 110px;

      .line {
        padding-right: 208px;
        grid-template-columns: 1fr 100px;
        column-gap: 20px;
        margin-bottom: 23px;
        align-items: flex-end;
      }
    }

    &:last-child {
      margin-bottom: 50px;
    }

    .sectionTitle {
      margin-bottom: 29px;
      margin-top: 31px;
    }
  }

  .line {
    display: grid;
    grid-template-columns: 147px 1fr;
    margin: 20px 0;

    &.invoiceNumberLine {
      align-items: flex-start;
      margin-bottom: 10px;
      min-height: 64px;

      & + .line {
        margin-top: 10px;
      }

      > label {
        margin-top: 10px;
      }

    }

    &.billToLine {
      align-items: flex-start;
      margin-bottom: 10px;
      height: 64px;

      & + .line {
        margin-top: 10px;
      }

      > label {
        margin-top: 10px;
      }
    }

    &.billFromLine {
      label {
        padding-top: 13px;
        align-items: flex-start;
      }
    }

    &.accountLine {
      grid-template-columns: 140px 1fr 150px;
      grid-column-gap: 20px;
    }

    label {
      display: flex;
      align-items: center;
    }
  }

  .separator {
    margin: 0 30px 0 27px;
    border-bottom: 1px solid #f0f2f9;
  }
}

.header {
  border-bottom: 1px solid #f0f2f9;
  padding: 22px 72px 21px;
  position: relative;
  display: flex;

  :global(.closeButton) {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
  }

  :global(.title) {

  }
}

.panel {
  display: grid;
  grid-template-columns: 1fr 265px;
  grid-template-areas: "main sd";
}

.billFromStyles {
  textarea {
    min-height: 105px;
  }
}

.additionalFieldsSelector {
  margin-top: 32px;

  button {
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    > div:first-child {
      margin-right: 11px;
    }
  }
}

.templateSelectorWrapper {
  margin-top: 30px;

  .billToOption {
    grid-template-columns: 45px 1fr;
  }

  .singleOption {
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .listOption {
    display: block;
  }
}
