.wrapper {
  display: block;
  height: 100%;
  width: 100%
}

.header {
  border-bottom: 1px solid #f0f2f9;
  padding: 22px 72px 21px;
  position: relative;

  .closeButton {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
  }
}

.panel {
  display: grid;
  grid-template-columns: 1fr 265px;
  grid-template-areas: "main sd";
}

.mainContent {
  grid-area: main;
}

.sideContent {
  grid-area: sd;
  background: #ffffff;
  box-shadow: 0 1px 100px rgba(240, 242, 249, 0.5);
}

.sidePanelContent {
  .saveButton {
    padding: 29px 0 0;
    display: flex;
    justify-content: center;

    button {
      width: 205px;
    }
  }

  .tabs {
    padding: 8px 0 0 34px;

    .tab {
      display: flex;
      align-items: center;
      height: 49px;

      span:last-child {
        margin-left: 10px;
      }
    }
  }
}

.notification {
  position: absolute;
  top: 30px;
  right: 30px;

  > div {
    margin-bottom: 10px;
  }
}
