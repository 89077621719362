:global {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'chronobankicons-regular';
    src: url('./fonts/ChronoBankIcons.eot');
    src: url('./fonts/ChronoBankIcons.eot') format('embedded-opentype'), url('./fonts/ChronoBankIcons.woff') format('woff'), url('./fonts/ChronoBankIcons.ttf') format('truetype');
    src: url('./fonts/ChronoBankIcons.eot') format('embedded-opentype'), url('./fonts/ChronoBankIcons.woff') format('woff'), url('./fonts/ChronoBankIcons.ttf') format('truetype');
  }

  html {
    height: 100%;
    font-size: 100%;
  }

  body {
    height: 100%;
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #151828;

    overscroll-behavior-y: none;
  }

  #root {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  button {
    cursor: pointer;
    outline: 0;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  button:focus {
    outline: 0;
    background: none;
  }

  select::-moz-focus-inner {
    border: 0;
  }

  select {
    outline: 0;
  }

  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .h0 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 123%;
  }

  .h1 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: 800;
    font-size: 54px;
    line-height: 121%;
  }

  .h2 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 160%;
  }

  .h3 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 118%;
  }

  .h4 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 118%;
  }

  .h5 {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
  }

  .capture {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .capture-small {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
  }

  .caption {
    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  /* additional colors */

  .yellow {
    color: #ffdb21;
  }

  .green {
    color: #79c573;
  }

  .red {
    color: #f97c7c;
  }

  /* text colors */

  .notes {
    color: #d9dbe4;
  }

  .gray-30 {
    color: #b9babf;
  }

  .gray-50 {
    color: #8a8b93;
  }

  .gray-70 {
    color: #5b5d69;
  }

  .gray {
    color: #151828;
  }

  .black {
    color: #333333;
  }

  /* color colors */

  .bk {
    color: #f6fbfb;
  }

  .gray-bk {
    color: #f0f2f9;
  }

  .blue-bk {
    color: #1436e5;
  }

  .turquoise-bk {
    color: #50eff5;
  }

  .white-bk {
    color: #ffffff;
  }

  td {
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  iframe#launcher {
    z-index: 999999 !important;
  }

  @keyframes :global(errorAnimation) {
    0% {
      transform: translateX(0px);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(0px);
    }
    75% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @media screen and (max-width: 1024px) {
    .hide-mobile {
      display: none !important;
    }
  }

  @media screen and (min-width: 1025px) {
    .hide-desktop {
      display: none !important;
    }
  }
}
