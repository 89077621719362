.blockchainPartContainer {
  width: 100%;
  display: flex;
  min-height: 165px;
  flex-direction: column;
  margin-top: 23px;
  padding: 0 5px;
}

.exchangeIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
  background-image: url("../../images/activity.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.billIcon {
  position: absolute;
  top: 12px;
  z-index: 100;
  min-width: 24px;
  min-height: 24px;
  margin-right: 15px;
  background-image: url("../../images/bill.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.feeSelect {
  width: 100%;
  margin: 0;
  background-color: white;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  padding: 0;
  font-size: 13px;
  line-height: 130%;
  color: #5b5d69;
  text-align: left;
  outline: none;
  height: 100%;
  appearance: none;
}

.walletRelativeContainer {
  margin-right: 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 51px;
  position: relative;
  align-items: center;

  .accountWrapper {
    width: 100%;
  }

  .connectWrapper {
    margin-bottom: 10px;
  }
}

.feeRelativeContainer {
  margin-right: 0 !important;
  display: flex;
  position: relative;
  width: 100%;
  height: 51px;
  align-items: center;

  .accountWrapper {
    width: 100%;
  }
}

.walletBalance {
}

.walletNotEnoughMoney {
  display: flex;
  width: 100%;
  color: #f97c7c;
  margin-left: 10px;
  font-family: "Nunito Sans", Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
}

.totalDebited {
  align-items: center;
  display: grid;
  grid-template-columns: 39px 1fr;

  > div:first-child {
    transform: translateY(-7px);
  }
}

.lineText {
  display: flex;
  width: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #5b5d69;
}

.walletLine {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f2f9;
  flex: 1;
  min-height: 38px;
  padding: 15px 8px 15px 0;

  &.accounts {
    padding: 3px 0;
  }

  :global(.chevron) {
    position: absolute;
    right: 30px;
  }

  &:last-child {
    border-bottom: none;
  }

  > div:first-child {
    margin-right: 15px;
  }

  .checkbox {
    transform: scale(0.67);
  }

  select {
    z-index: 1;
    background: transparent;
    overflow: hidden;
    white-space: normal;
    padding-right: 15px;
    text-overflow: ellipsis;
  }
}

.mainTotal {
  display: flex;
  justify-content: center;
  margin: 0 0 10px;

  > div:first-child {
    margin-right: 10px;
  }
}

.header {
  padding: 0 0 8px 0;

  :global(.add-btn) {
    padding: 10px 55px;
    width: 100%;
  }

  .headerConfirm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    margin-left: -5px;

    :global(.add-btn) {
      padding: 10px 55px;
      width: 100%;
    }

    :global(.confirm-title) {
      flex: 1;
      text-align: left;
      padding: 0 0 0 10px;
    }
  }
}

.header + div {
  margin: 0;
}

.separatedLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -29px;
  width: 160px;
}

.resetPasswordButton {
  padding-top: 10px;
  text-align: right;

  button {
    width: auto;
  }
}

.optionWrapper {
  .singleOption {
    display: none;
    align-items: center;
    white-space: normal;

    .fakeIcon {
      min-width: 24px;
      width: 24px;
      max-width: 24px;
    }

    .ellipsis {
      overflow: hidden;

      > div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    > div:first-child {
      margin-right: 15px;
    }
  }

  .listOption {
    text-align: left;
  }

  .walletsListOption {
    display: grid;
    grid-template-columns: 34px 1fr;
    align-items: center;
  }
}
