.formWrapper, .formWrapperMnemonic {
  margin-top: 32px;
  margin-bottom: 40px;
  width: 324px;
  position: relative;

  .loaderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    margin-bottom: 20px;
  }

  .actions, .actionsMnemonic {
    display: flex;
    justify-content: space-between;

    button:first-child {
      width: 110px;
    }

    button:last-child {
      width: 195px;
    }
  }

  .actionsMnemonic {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    button + button {
      width: 195px;
      margin-left: 20px;
    }
  }
}

.formWrapperMnemonic {
  width: 440px;
}

.wordsGrid {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.word {
  background: #f6fbfb;
  border-radius: 5px;
  padding: 11px 0;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  color: #333333;
  border: 1px solid #f6fbfb;

  span {
    margin-right: 10px;
    color: #C1C1C1;
  }

  &:global(.active) {
    background: #FFFFFF;
    border: 1px solid #1436E5;
    box-sizing: border-box;
    color: #1436E5;
  }
}

.copyButtonWrapper {
  display: flex;
  margin-top: 30px;

  :global(.button) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  :global(.copy-message) {
    right: -12px;
  }
}

.confirmedBlocksWrapper {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .confirmedBlock {
    background: #50EFF5;
    border-radius: 5px;
    padding: 11px 0;
    text-align: center;
  }
}

.formTitle {
  margin-bottom: 20px;
}

.subTitleWrapper {
  display: grid;
  grid-template-columns: 1fr 75px;

  .resetBlock {
    align-items: center;
    display: flex;
    flex-direction: row;

    span {
      margin-left: 10px;
    }
  }

}

.confirmError {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
}

.confirmLoaderWrapper {
  width: 324px;
  height: 300px;
  background: white;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageContainer {
  width: 324px;
  margin-left: -25px;
  margin-top: 42px;
  margin-bottom: 40px;
}
