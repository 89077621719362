.label {
  transition: opacity 0.3s;
  z-index: -1;
  top: -1000px;
  color: #ffffff;
  opacity: 0.1;
  display: block;
  position: fixed;
  text-align: center;
  width: max-content;
  max-width: 300px;
  word-break: normal;
  white-space: normal;
  line-height: 22px;
  background: #151828;
  border-radius: 4px;
  padding: 8px 15px;

  .arrow {
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: #151828;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
  }

  .buttonWrapper {
    button {
      margin: 10px auto 0;
    }
  }
}
