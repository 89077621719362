.container {
  margin: 0;
  min-height: 70px;
  max-width: 1200px;
  cursor: pointer;
  box-shadow: 0 -1px 0 #f0f2f9;
  display: inline-grid;
  grid-template-columns: 24px 170px 234px 1fr 150px;
  padding-left: 20px;
  padding-right: 20px;

  &:global(.open) {
    grid-template-columns: 24px 170px 119px 1fr 150px;
    background: #f6fbfb;
  }

  > div {
    align-items: flex-start;
    padding-top: 25px;
    display: flex;
  }

  .chevron {
  }

  .status {
    padding-left: 20px;

    span:last-child {
      padding-left: 16px;
    }
  }

  .date {
    padding-left: 20px;
  }

  .address {
    padding-left: 20px;
    min-width: 380px;
    text-overflow: ellipsis;
    overflow: hidden;

    span:last-child {
      padding-left: 16px;
      word-break: normal;
      white-space: nowrap;
    }
  }

  .amount {
    justify-content: flex-end;
    &:global(.green) {
      color: #79c573;
    }

    &:global(.red) {
      color: #f97c7c;
    }
  }

  .txDetailTable {
    display: grid;
    grid-template-columns: 120px 1fr;
    > div {
      color: #8a8b93;
      text-align: left;
      padding-bottom: 25px;
      vertical-align: top;
      line-height: 24px !important;
      &:nth-child(odd) {
        text-align: right;
        white-space: nowrap;
        padding-right: 15px;
      }
      &:nth-child(even) {
        color: #5b5d69;
        padding-left: 15px;
      }
      .hashContainer {
        display: flex;
        align-items: center;

        div:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 333px;
        }
      }

      .addressesContainer {
        > div {
          display: flex;
          align-items: center;

          span {
            margin-right: 14px;
          }
        }
      }

      .explorerLink {
        display: flex;
        align-items: center;
      }
    }
  }
}
