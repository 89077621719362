.accountOption {
  display: grid;
  align-items: center;
  grid-template-areas: "icon name""icon address";
  grid-template-columns: 35px 1fr;
  width: 100%;
  overflow: hidden;

  >.accountOptionIcon {
    grid-area: icon;
    display: grid;
    grid-row: 1 / span 2;
  }

  >div.accountOptionName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: name;

    span {
      &:global(.address) {
        display: none;
      }
    }
  }

  >div.accountOptionAddress {
    grid-area: address;
  }
}

div.trezorConnectWrapper {
  width: calc(100% + 24px);
  height: 61px;
  margin: -13px -12px;

  &:hover {
    background: #f6fbfb;
  }
}