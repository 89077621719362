.messageContainer {
  width: 385px;
}

.messageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  > div {
    margin: 5px 0;
    text-align: center;

    &:first-child {
      transform: scale(2);
      margin: 0 0 18px 0;
    }
  }
}

.messageActions {
  display: flex;
  justify-content: space-around;
  margin-top: 14px !important;

  button {
    margin: 0 11px;
  }
}