@import '../../styles/index.module.scss';

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  font-size: 13px;
  color: $colorDarkGrey;
  transition: all 1.5s linear;
  position: relative;

  &:hover {
    .label {
      color: $colorGreen;
    }
    .slider:before {
      width: 22px;
      height: 22px;
      top: -4px;
    }
  }
}

.iconWrapper {
  position: absolute;
  right: 8px;
  cursor: pointer;
}
