.settingsWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.renameFormContainer {
  form {
    display: grid;
    grid-template-columns: 128px 324px 97px;
    width: 549px;
    margin: 0 auto;
    justify-content: center;
    align-items: flex-start;
    grid-column-gap: 20px;
    position: relative;

    .renameLabel {
      margin-top: 10px;
    }

    .renameInput {

    }

  }

  .optionsList {
    width: 590px;
    margin: 50px auto;

    .option {
      cursor: pointer;
      display: grid;
      grid-template-areas: 'icon title' 'icon descr';
      grid-template-columns: 99px 1fr;
      grid-template-rows: 54px 55px;
      align-items: center;
      border-radius: 3px;
      border: 1px solid transparent;
      margin: 10px auto;

      &:hover {
        border-color: #F0F2F9;
      }

      .optionIcon {
        grid-area: icon;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          transform: scale(2, 2);
        }
      }

      .optionTitle {
        height: 100%;
        grid-area: title;
        display: flex;
        align-items: flex-end;
        padding-bottom: 3px;
      }

      .optionDescr {
        height: 100%;
        grid-area: descr;
        display: flex;
        align-items: flex-start;
        padding-top: 7px;
      }
    }
  }

}

.loaderWrapper {
  display: flex;
  justify-content: center;

  div {
    margin: 5px auto;
  }
}

.notificationWrapper {
  position: absolute;
  right: -234px;
}