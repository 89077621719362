.formWrapper {
  margin-top: 32px;
  margin-bottom: 40px;
  width: 324px;
  position: relative;

  .loaderWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .line {
    margin-bottom: 20px;


  }

  .actions {
    display: flex;
    justify-content: space-between;

    button:first-child {
      width: 110px;
    }

    button:last-child {
      width: 195px;
    }

  }
}