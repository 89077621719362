.paymentAssetsTableWrapper {
  .titles {
    display: grid;
    grid-template-columns: 175px 1fr;

    div {

    }
  }

  .table {
    display: grid;
    margin-top: 10px;
    width: 730px;
    grid-template-columns: 700px 30px;

    .removedActions {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
      }
    }

    .content {
      display: grid;
      grid-template-columns: 175px 1fr;
      grid-gap: 1px;
      background-color: #d9dbe4;
      border: 1px solid #d9dbe4;
      border-radius: 3px;

      > div {
        &:first-child {
          div {
            border-top-left-radius: 3px;
          }
        }

        &:last-child {
          div {
            border-bottom-right-radius: 3px;
          }
        }

        &:nth-child(2) {
          div {
            border-top-right-radius: 3px;
          }
        }

        &:nth-last-child(2) {
          div {
            border-bottom-left-radius: 3px;
          }
        }
      }
    }
  }

  .actionWrapper {
    margin-top: 10px;

    button {
      display: flex;
      align-items: center;

      > div {
        margin-right: 10px;
      }
    }
  }
}
