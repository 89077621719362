.wrapper {
  display: block;
  height: 100%;
  width: 100%
}

.header {
  border-bottom: 1px solid #f0f2f9;
  padding: 22px 72px 21px;
  position: relative;

  .closeButton {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 24px;
    height: 24px;
  }
}

.panel {
  display: grid;
  grid-template-columns: 1fr 265px;
  grid-template-areas: "main sd";
}

.mainContent {
  grid-area: main;

  .qr-code {
    display: none;

    &.open {
      display: block;
    }
  }
}

.loadinState {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 40px;
  width: 40px;
  background: url('../../images/paymentx-rolling-loader.svg');
  background-size: cover;
}

.sideContent {
  grid-area: sd;
  background: #ffffff;
  box-shadow: 0 1px 100px rgba(240, 242, 249, 0.5);

  .SideContent__content {
    padding: 31px 27px 31px 30px;
  }

  .request-pay-container {
    padding: 0 !important;
    width: 100% !important;
  }

  .wallet-line {
    margin-right: 0 !important;
  }

  .wallet-title {
    margin-right: 0 !important;
  }

  .nowallets-container {
    height: 100% !important;
    justify-content: flex-start !important;
    margin-top: 50px;
  }

  .confirm-requestpay-container {
    padding-left: 0 !important;
    width: 100% !important;
    padding-right: 0 !important;
  }
}

.sidePanelContent {
  .mainData {
    display: grid;
    grid-template-rows: 60px 45px 34px 30px;
    margin-left: 34px;
    margin-bottom: 26px;
    max-width: 200px;

    .walletType {
      display: flex;
      align-items: center;
      padding: 23px 0 0 0;

      span:last-child {
        margin-left: 10px;

      }
    }

    .walletBalance {
      display: flex;
      align-items: flex-end;

      > span:first-child {
        margin-right: 10px;
      }
    }

    .walletAmount {
      display: flex;
      align-items: center;
      padding-top: 3px;
    }

    .walletLabels {
      display: flex;
      align-items: center;

      .walletLabel {
        padding: 2px 10px;
        margin-right: 15px;
        background: #FFFFFF;
        border: 1px solid #F0F2F9;
        border-radius: 3px;
      }
    }
  }

  .separator {
    border: 1px solid #F0F2F9;
    margin: 0 25px 0 40px;
  }

  .tabs {
    padding: 17px 0 0 34px;

    .tab {
      display: flex;
      align-items: center;
      height: 49px;

      span:last-child {
        margin-left: 10px;
      }
    }
  }
}

