.paymentsContainer {
  max-width: 1177px;
  min-width: 320px;
  margin: 0 auto;
  padding-bottom: 40px;
  position: relative;

  .mobilePanelPointer {
    position: absolute;
    height: 0;
    width: 0;
    top: 36px;
    left: 130px;
  }

  .exportButtonWrapperLoader {
    position: absolute;
    display: flex;
    top: -5px;
    right: 85px;

    div {
      margin: 0;
      padding: 0;
    }
  }

  .exportButtonWrapper {
    position: absolute;
    display: flex;
    top: 24px;
    right: 40px;

    button {
      display: flex;
      align-items: center;

      div:last-child {
        margin-left: 10px;
      }
    }
  }

  .nothingMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 265px);

    div {
      &:first-child {
        transform: scale(2);
        margin-bottom: 20px;
      }

      text-align: center;
    }
  }

  .loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 265px);
  }

  .table {
    width: 100%;
    margin: 26px 0 100px;

    tr {
      overflow: hidden;
      cursor: pointer;
      border-bottom: 1px solid #F0F2F9;

      &:hover {
        background-color: #F6FBFB;

        .checkbox {
          > div {
            visibility: visible;
          }
        }
      }

      &.exchangeRateLine {
        &:hover {
          td {
            span {
              display: none;
            }
          }
        }
      }

      &.headerTitle {
        &:hover {
          background-color: inherit;
        }

        td {
          padding: 0;

          > div {
            display: grid;
            grid-template-columns: 57px 1fr;
            grid-template-areas: "checkbox pending";
            align-items: center;

            > span {
              padding-left: 10px;
              grid-area: pending;
            }

            .headerCheckbox {
              padding-left: 20px;
              grid-area: checkbox;
            }

            @media screen and (max-width: 1024px) {
              grid-template-columns: 1fr 64px;
              grid-template-areas: "pending checkbox";

              .headerCheckbox {
                padding: 0 20px;
              }

              > span {
                padding-left: 20px;
              }
            }
          }
        }
      }

      td {
        height: 70px;
        min-width: 20px;
        padding: 0 5px;

        &.checkboxDesktop {
          display: table-cell;
        }
        &.checkboxMobile, &.tdCreatedAt, &.tdInvoiceNumber, &.paymentAsset, &.tdName, &.avatar {
          display: none;
        }

        &.groupDate {
          padding-left: 65px;
        }

        @media (hover: hover) {
          &.checkbox, .checkbox {
            > div {
              visibility: hidden;
            }
          }
        }

        @media screen and (min-width: 480px) {
          &.tdCreatedAt {
            display: table-cell !important;
          }
        }
        @media screen and (min-width: 800px) {
          &.tdAvatar, &.tdName, &.paymentAsset, &.avatar {
            display: table-cell !important;
          }
        }
        @media screen and (min-width: 1025px) {
          &.tdInvoiceNumber {
            display: table-cell !important;
          }
          &.tdCryptoAmount {
            padding-right: 45px;
          }
        }
        @media screen and (max-width: 1024px) {
          &.checkboxMobile {
            display: table-cell !important;
          }
          &.checkboxDesktop {
            display: none !important;
          }

          &.groupDate {
            padding-left: 20px;
          }
          &.checkbox, .checkbox {
            width: 40px !important;
            padding: 0 20px;
          }
        }

        &.checkbox, .checkbox {
          width: 65px;
          padding-left: 20px;

          &.visible {
            > div {
              visibility: visible;
            }
          }
        }

        &.status {
          > div {
            height: 100%;
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            padding-left: 10px;
            grid-column-gap: 10px;
          }
        }

        &.paymentAsset {
          > div {
            height: 100%;
            display: grid;
            grid-template-columns: 34px 1fr;
            align-items: center;
          }
        }

        &.avatar {
          max-width: 46px;
          padding-right: 10px;

          > div {
            margin: 0 auto;
          }
        }

        .amountWrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: flex-end;
          justify-content: center;

          font-family: 'Nunito Sans', Arial;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 120%;

          div:global(.amount) {
            text-align: right;
            color: #F97C7C;
          }

          &:global(.plus) {
            div:global(.amount) {
              color: #79C573;
            }
          }

          div:global(.amountCrypto) {
            font-weight: normal;
            font-size: 14px;
            margin-top: 2px;
            opacity: 0.5;
            color: #333333;
          }
        }

      }
    }
  }
}
