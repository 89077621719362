.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  min-height: 422px;

  .form {
    display: block;
    width: 324px;

    > div {
      margin-bottom: 20px;
    }

    .title {

    }

    .input {
      textarea {
        height: 70px;
      }
    }

    .action {
      button {
        width: 100%;
      }
    }
  }

}
