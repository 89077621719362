.wrapper {
  width: 250px;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
    height: auto;
    background-color: white;
  }
}
