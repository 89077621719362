.label {
  display: block;
  width: 100%;
  font-family: 'Nunito Sans', Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #5b5d69;
  margin-bottom: 6px;
}

.errorText {
  margin: 5px 0;
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 10px;
  }
}
