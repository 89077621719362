.messageContainer {
  width: 590px;
  min-height: 495px;
}

.messageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 93px;
  min-height: 422px;

  > div {
    margin: 5px 0;
    text-align: center;

    &:first-child {
      transform: scale(2);
      margin: 0 0 18px 0;
    }
  }
}

.messageHeader {
  display: grid;
  height: 72px;
  padding: 0 24px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  border-bottom: 1px solid #F0F2F9;;
}

.messageActions {
  display: grid;
  justify-content: space-around;
  margin-top: 14px !important;
  grid-template-columns: 169px 266px;
  grid-column-gap: 20px;
}