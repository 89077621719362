.button {
  width: 100%;
  height: 100%;
  padding: 13px 12px;

  .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;

    .loader {
      margin: 0;
      width: 24px;
      height: 24px;
    }
  }

  .buttonContent {
    display: grid;
    grid-template-columns: 35px 1fr;
    align-items: center;
    text-align: left;
  }
}

