.paymentDetailsBlockWrapper {
  .title {
    border-bottom: 1px solid #F0F2F9;
    padding-bottom: 6px;
  }

  .accountName {
    margin-top: 16px;
    margin-bottom: 34px;
  }

  .walletLine {
    display: flex;
    align-items: center;
    padding-top: 15px;

    > div:first-child {
      margin-right: 12px;
    }

    .walletNameWrapper {
      .walletName {
        position: relative;
        height: 19px;
        > div {
          &:first-child {
            opacity: 0;
          }

          &:last-child {
            position: absolute;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            top: 0;
          }
        }
      }

      .walletName + .walletAddress {
        padding-top: 6px;
      }

      > div:last-child {
        display: flex;
        align-items: center;
        height: 24px;

        button {
          margin-left: 5px;
        }
      }

      .walletAddress {
        display: flex;
      }
    }

  }

  .transactionTable {
    margin-left: 35px;

    > div {
      display: grid;
      grid-template-columns: 150px 1fr;
      margin-bottom: 25px;
    }

    .viewOnBlockchainButton {
      display: flex;
      align-items: center;

      > div {
        margin-right: 10px;
      }
    }

    .walletLine {
      padding-top: 0;
    }

    .transactionHashWrapper {
      display: flex;
      align-items: center;

      .transactionHash {
        width: 300px;
        text-overflow: ellipsis;
        word-break: normal;
        overflow: hidden;
      }
    }
  }

  .actionButton {
    margin-left: 10px;
  }
}
