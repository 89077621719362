@import "../../styles/index.module";

.wrapper {
  position: relative;
  height: 42px;
  width: 100%;
  min-width: 70px;
}

.iconWrapper {
  &:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: 9px;
    right: 9px;
    background-image: url('../../images/icons-sprite-50.svg');
    background-position: -168px -24px;
    background-repeat: no-repeat;
  }
}

.prefix {
  font-size: 15px;
  color: $colorGrey14;
  position: absolute;
  top: 9px;
  right: 37px;
}

.styledSelect {
  font-family: 'Nunito Sans', Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;

  width: 100%;
  padding: 0 15px;
  margin: 0;
  border: solid 1px $colorGrey12;
  text-decoration: none;
  background-color: $colorWhite;
  border-radius: 4px;
  color: #5b5d69;
  text-align: left;
  outline: none;
  height: 42px;
  appearance: none;
  z-index: 1;

  &[disabled] {
    cursor: default;
    background-color: $colorGrey5;
    color: $colorGrey14;
  }
  &[prefix] {
    padding-right: 79px;
  }

  &.error {
    border: solid 1px $colorRed;
    color: $colorRed;

    &[disabled] {
      color: $colorRed;
    }
  }
}

.label {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #5B5D69;
  margin-bottom: 5px;
}
