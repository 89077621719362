
@mixin icon-type-position($position) {
  background-position: $position
}
@mixin icon-url($url) {
  background-image: $url
}

$iconTypes: (
  wallet: 0 0,
  refresh-double: -24px 0,
  collapse-down: -48px 0,
  time: -72px 0,
  refresh-left: -96px 0,
  arrow-to-top: -120px 0,
  attention: -144px 0,
  checkmark-circle: -168px 0,
  info-circle: -192px 0,
  close-circle: -216px 0,
  eye: -240px 0,
  eye-slash: -264px 0,
  user-circle: -288px 0px,
  mail: -312px 0,
  copy-2: -336px 0,
  calendar: -384px 0,
  plus: -192px -48px,
  sum: -216px -48px,
  plus-circle: -24px -24px,
  minus-circle: -24px -72px,
  bitcoin-sign: -48px -24px,
  arrow-left: -72px -24px,
  help: -96px -24px,
  file-pdf: -144px -24px,
  file-empty: -144px -48px,
  chevron-down: -168px -24px,
  chevron-up: -192px -24px,
  close: -216px -24px,
  qrcode: -240px -48px,
  settings: -240px -24px,
  lock: -264px -48px,
  cloud-upload: -120px -48px,
  image-circle: -168px -48px,
  edit-square: -312px -24px,
  clock: -384px -24px,
  block: 0 -48px,
  link: -264px -48px,
  unlink: -312px -48px,
  password-2: -288px -48px,
  activity: -384px -48px,
  sign-out: -336px -48px,
  draft: -48px -72px,
  trezor: -216px -72px,
  csv: -192px -72px,
  ethereum: -288px -72px,
  BTC: -48px -24px,
  ETH: -288px -72px,
);

$colors: (
  gray-30: url(../../images/icons-sprite-30.svg),
  gray-50: url(../../images/icons-sprite-50.svg),
  gray-70: url(../../images/icons-sprite-70.svg),
  blue: url(../../images/icons-sprite-blue.svg),
  red: url(../../images/icons-sprite-red.svg),
  white: url(../../images/icons-sprite-white.svg),
  green: url(../../images/icons-sprite-green.svg),
  orange: url(../../images/icons-sprite-orange.svg),
);

.styledIcon {
  min-width: 24px;
  width: 24px;
  max-width: 24px;
  min-height: 24px;
  height: 24px;
  max-height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;

  @each $type, $position in $iconTypes {
    &.icon-#{$type} {
      @include icon-type-position($position);
    }
  }

  @each $color, $url in $colors {
    &.#{"" + $color} {
      @include icon-url($url);
    }
  }

  &.scale-150 {
    transform: scale(1.5)
  }
}
