.wrapper {
  font-family: 'Nunito Sans', Arial;
  display: flex;
  margin: 10px 25px 10px 0;
  position: relative;
  padding: 12px 0;
  cursor: pointer;
  border-bottom: 1px solid #F0F2F9;
  min-height: 90px;

  .information {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    color: #333333;

    .accountBalanceLoader {
      width: 30px;
      height: 30px;
      margin: 0 5px 0 0;
      display: inline-flex;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
  }
}

.currencyContainer {
  border-radius: 10px;
  width: 46px;
  height: 46px;
  flex-grow: 0;
  flex-shrink: 0;
  box-shadow: 0 2px 3px rgba(240, 242, 249, 0.5);

  background: #ffffff;
  border: 1px solid #506cff;
  box-sizing: border-box;

  &:global(.isTestnet) {
    background: #F6FBFB;
    border-color: transparent;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #8A8B93;
}

.summ {
  margin-top: 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: rgba(21, 24, 40, 0.7);
}

.convert {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #B9BABF;
}

.connectButtonWrapper {
  margin-top: 10px;

  button {
    height: 20px;
  }
}
