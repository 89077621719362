.wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 230px;
  background: #ffffff;
  z-index: 99999999999999;
  box-shadow: 0 10px 50px #B9BABF;
  border-radius: 4px;
  overflow: hidden;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .content {
    box-shadow: inset 6px 0 0 #1436E5;
    padding: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;


    .icon {
      transform: scale(2);
      padding: 10px;
    }

    .title {
      margin-top: 20px;
      text-align: center;
    }

    .subTitle {
      margin-top: 10px;
      text-align: center;
    }

    .action {
      margin-top: 1px;
    }
  }
}

