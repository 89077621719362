.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 130px);
  justify-content: center;
  min-height: 400px;

  .formContainer {
    display: grid;
    grid-template-columns: 147px 324px;
    grid-row-gap: 20px;

    > div {
      &:global(.label) {
        padding-top: 13px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      margin-bottom: 10px;

      .fiatAmount {
        display: flex;
      }
    }

    .select {
      > div {
        height: 42px;
      }
    }

    .sendBtn {
      width: 100%;
    }

    .resetPasswordButton {
      padding-top: 10px;
      text-align: right;

      button {
        width: auto;
      }
    }
  }
}

.fakeForAutofill {
  height: 0;
  width: 0;
  overflow: hidden;
}

.loaderWrapper {
  display: flex;
  justify-content: center;

  div {
    margin: 5px auto;
  }
}

