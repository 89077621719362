.block {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
  margin: 0 30px;
  border-bottom: 1px solid #F0F2F9;

  &.descriptionBlock {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    .rowWrapper:first-child+.rowWrapper {
      margin-top: 17px;
    }

    .rowWrapper+.rowWrapper {
      margin-top: 28px;
    }

    .rowWrapper .rowTitle {
      padding-bottom: 12px;
      display: flex;
      align-items: center;

      .billExampleLabelWrapper {
        max-width: 410px;
      }

      .billExample {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 5px;

        img {
          margin: 10px 2px 8px;
        }
      }

      >span:first-child {
        margin-right: 10px;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .avatarBlock {
    display: flex;
    flex-direction: column;
    align-items: center;

    >label {
      margin-bottom: 15px;
    }

    >div {
      margin-top: 5px;
    }

    .version {
      background: #F6FBFB;
      border-radius: 3px;
      padding: 5px 10px;
      margin-top: 10px;
    }
  }

  .rowWrapper {
    width: 472px;

    .rowTitle {
      padding-bottom: 30px;
    }

    .line {
      display: grid;
      grid-template-columns: 147px 1fr;
      align-items: center;
      margin-bottom: 20px;

      &.singleLine {
        grid-template-columns: 1fr;
      }

      &.checkboxLine {
        grid-template-columns: 50px 1fr;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;

  div {
    margin: 30px auto 0;
  }
}

.avatarErrorWrapper {
  position: absolute;
  top: 30px;
  right: 30px;
}

.checkbox16 {
  transform: scale(0.8);
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.checkboxDescriptionContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;

  &:first-child {
    color: #151828;
  }

  >div {
    display: flex;
    font-size: 13px;
  }
}

.avatarWrapper {
  width: 100px !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-image: url('../../../images/default_avatar.svg');
  background-repeat: no-repeat;
  background-color: #f9f9f9;
  background-position: center center;
  opacity: 1 !important;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;

  .avatarIcon {
    width: 42px;
    height: 42px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    bottom: -10px;
    right: -10px;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0 3px 5px rgba(20, 54, 229, 0.1);
    align-items: center;
    justify-content: center;
  }
}

.hintContainer {
  border-radius: 4px;
  width: 632px;
  margin-top: 48px;

  .hintTitle {
    margin-bottom: 10px;
  }

  .hintBody {
    position: relative;
    min-height: 280px;
    display: flex;
    justify-content: flex-end;
    padding: 30px 30px 120px 10px;
    background-image: url('../../../images/previewPdfBackground.svg');
  }

  .hintInvoiceNumber {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 172%;
    color: #000000;
    position: absolute;
    bottom: 70px;
    left: 40px;
  }

  .hintInfo {
    max-width: 500px;
    white-space: pre;
    overflow: hidden;

    div {
      overflow: hidden;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 130%;
      text-align: right;
      color: #151828;
    }
  }

  .hintLogo {
    width: 178px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 26px;
    left: 26px;
    color: #D9DBE4;
    position: absolute;
    border: 2px dashed #F0F2F9;
    box-sizing: border-box;
    border-radius: 6px;
  }
}