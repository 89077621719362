.wrapper {
  display: flex;
  flex-direction: column;
  width: 244px;
  height: 100%;
  padding-left: 15px;
  font-family: 'Nunito Sans', Arial;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 0 15px;
    background-color: white;
  }

  .walletListContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    min-height: 150px;
  }

  .addNewWalletMobile {
    display: flex;
    height: 25px;
  }

  .walletsMobile {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 50px;
    align-items: baseline;
  }

  .emptyContainer {
    height: 500px;
    min-height: 500px;
    border: 1px solid red;
  }

  .mobileTitle {
    display: inline-flex;
    flex: 1;

    font-family: 'Nunito Sans', Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 250%;
    color: #5B5D69;
  }
  .emptyWalletsContainer{
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .mobileTotalBalance {
    display: inline-flex;
    text-align: center;
    width: auto;
    align-items: baseline;

    span:first-child {
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      color: #8A8B93;
      padding-right: 10px;
    }
    &.totalAmount {
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: #5B5D69;
      padding-right: 10px;
    }
    &.currency {
      font-weight: normal;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0 0;
    //height: 100%;

    .addBtn {
      padding: 10px 55px;
    }

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  .accountsContainer {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding-top: 8px;

    .totalRow {
      width: 100%;
      color: #808080;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 25px 20px 0;

      .totalTitle {
        color: rgba(21, 24, 40, 0.5);
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 5px;
      }

      .summ {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 118%;
        color: rgba(21, 24, 40, 0.7);
        display: flex;

        span.currency {
          font-weight: normal;
        }

        .totalLoader {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 0 5px;
        }
      }

    }

    .walletsTitle {
      display: flex;
      min-height: 40px;
      justify-content: space-between;
      margin-bottom: 15px;
      border-bottom: 1px solid #F0F2F9;
      padding-bottom: 15px;
      margin-right: 25px;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: rgba(21, 24, 40, 0.7);
      }

      button {
        padding: 0;
      }

      @media screen and (max-width: 1024px) {
        margin-right: 0;
      }
    }
  }

  .addWalletLine {
    padding-top: 14px;

    button {
      display: flex;
      align-items: center;

      div {
        margin-right: 9px;
      }
    }
  }

  .emptyMessage {
    padding-right: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: calc(100vh - 390px);
    min-height: 170px;

    > div:first-child {
      transform: scale(2, 2);
      transform-origin: center bottom;
      margin-bottom: 5px;
    }

    @media screen and (max-width: 1024px) {
      height: auto;
    }
  }
}
