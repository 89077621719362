
.container {
  width: 244px;
  padding-top: 0;
  padding-bottom: 40px;
  overflow: visible;
  padding-left: 15px;
  padding-right: 22px;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', Arial;

  .loader {
    height: 40px;
    width: 40px;
    margin: 0;
  }

  .requestAmount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #B9BABF;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .blockchainPartLoadingContainer {
    display: flex;
    height: 245px;
    margin-left: -30px;
    margin-right: -6px;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
  }
}

.blochainPartWrapper {
  height: 100%;
  padding: 0 15px;
  min-height: 400px;
}

.mainTotal {
  grid-area: amount;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 0 12px;
  min-height: 40px;

  >div:first-child {
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}
.payButton {
  grid-area: button;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.header {
  padding: 22px 0 7px 0;
  display: flex;
  flex-direction: column;

  :global(.add-btn) {
    padding: 10px 55px;
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 20px 15px 7px 15px;

    :global(.add-btn) {
      padding: 10px 0;
      margin-top: 0;
      width: 100%;
    }
  }
}

.noWalletsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 240px;
  margin-top: 40px;
  height: auto;
  margin-right: 50px;
  align-items: center;
  justify-content: center;

  .noWalletsInnerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    .noWalletsIcon {
      display: flex;
      width: 40px;
      height: 36px;
      margin-bottom: 15px;

      >div {
        transform: scale(2);
      }
    }

    .noWalletsText {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      color: #B9BABF;

      .noWalletsTextList {
        margin-bottom: 27px;
      }

      .noWalletsTextSingle {
        margin-bottom: 8px;
      }
    }

    div.addNewWalletWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @media screen and (max-width: 1024px) {
        padding: 0 15px;
      }
    }

    .addNewWallet {
      margin-top: 22px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1436E5;
      cursor: pointer;

      >div {
        margin-right: 11px;
      }
    }
  }
}
