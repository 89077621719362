.content {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333333;
  font-size: 24px;
  overflow: hidden;
  font-family: 'Nunito Sans', Arial;

  button {
    width: 200px;
    height: 40px;
  }

  .errorIcon {
    transform: scale(3.5);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .errorMessage {
    margin-top: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #c1c1c1;
  }

  .reloadMessage {
    margin-top: 13px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #c1c1c1;

    button {
      padding: 0;
      border: none;
      border-bottom: 1px solid;
      color: #57b8b6;
      width: auto;
      height: 19px;
      cursor: pointer;
    }
  }

  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    margin: 40px;
    width: 45px;
    height: 45px;
    display: flex;
    background-image: url('../../images/paymentx-rolling-loader-no-ratate.svg');
    animation: rotating .8s linear infinite;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
