.tabTitle {
  margin: 30px 0 -1px 72px;
}

.receiveContainer {
  display: block;

  .receiveContent {
    height: calc(100vh - 300px);
    min-height: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .receiveAddress {
      display: flex;
      justify-content: center;
      margin: 20px 0 5px 0;
      align-items: center;

      span {
        margin-right: 5px;
      }
    }

    .receiveQRCodeWrapper {
      img {
        width: 200px;
        height: 200px;
      }
    }

    .sendEmailWrapper {
      margin-top: 12px;
      margin-bottom: 100px;

      .sendEmailGrid {
        display: grid;
        grid-template-columns: 34px 1fr;
        align-items: center;
      }
    }
  }
}

.messageContainer {
  width: 590px;
  min-height: 495px;
}

.messageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  min-height: 422px;

  > div {
    margin: 5px 0;
    text-align: center;

    &:first-child {
      transform: scale(2);
      margin: 0 0 18px 0;
    }
  }
}

.messageHeader {
  display: grid;
  height: 72px;
  padding: 0 24px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  border-bottom: 1px solid #F0F2F9;;
  text-align: left;
}

.messageActions {
  display: flex;
  justify-content: space-around;
  margin-top: 14px !important;

  button {
    margin: 0 11px;
  }
}
