.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  min-height: 422px;

  form {
    width: 324px;

    > div {
      &:first-child {
        margin-bottom: 10px;
      }

      margin-bottom: 20px;
    }

    button {
      width: 100%;
    }
  }

  .resetPasswordButton {
    text-align: right;

    button {
      width: auto;
    }
  }
}