.container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;


  .wrapper {
    text-align: center;
    .image {
      background-image: url('../../images/error-page-icon.svg');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 230px;
      height: 230px;
      margin: 0 auto 30px;
    }
    >div{
      margin-bottom: 20px;
    }
    button {
      margin: 0 auto;
    }
  }
}
